<template>
	<div class="bind-box-task">
		<div class="box-item" v-for="(item, index) in list" :key="index">
			<div class="left">
				<header>
					<img src="@/assets/task/box.png" alt="" srcset="" />
					<span>每日盲盒</span>
				</header>
				<div class="use">
					每日盲盒消耗{{ item.lim }}钻石（<span style="color: #e9b108">{{
            item.ls
          }}</span>/{{ item.lim }}）
				</div>
				<div class="jiangli">
					任务奖励： 保底{{ item.min }}
					<img src="@/assets/img/money.png" alt="" srcset="" />
					最高{{ item.max }}
					<img src="@/assets/img/money.png" alt="" srcset="" />
				</div>
			</div>
			<div class="right" style="color: #666" v-if="[1].includes(item.status)">
				已经领取
			</div>
			<div class="right" @click="consumeRecharge(item, index)" v-else>
				{{ item.status == 0 ? "立即前往" : "立即领取" }}
			</div>
		</div>
		<div class="win-box" v-if="money">
			<div class="win">
				<div class="win-conm"
					style="color: white;display: flex;align-items: center;flex-flow: row;justify-content: center;">
					<div class="win-conm">
						<div class="win-tips">恭喜获得</div>
					</div>
					<div>{{money}}</div><img src="@/assets/img/money.png" style="width: 24px;">
				</div>
				<div class="win-span2" @click="money = null">放入背包</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				money: null,
			};
		},
		created() {
			this.getBindBoxTaskList();
		},
		methods: {
			async getBindBoxTaskList() {
				const params = {};
				const {
					data
				} = await this.$axios.post(
					"/index/Activity/openbox_reward_info",
					this.$qs.stringify(params)
				);
				this.list = data.data.info;
				this.list.sort((a, b) => {
					return a['lim'] - b['lim']
				});
			},
			async consumeRecharge(item, index) {
				if (item.ls > item.lim) {
					const params = {
						id: item.id,
					};
					const {
						data
					} = await this.$axios.post(
						"/index/Activity/kaixiangtask",
						this.$qs.stringify(params)
					);
					if (data.status) {
						this.money = data.data;
						this.list[index].status = 1;
						this.list.sort((a, b) => {
							return a['lim'] - b['lim']
						});
						this.$parent.$parent.getPlayerInfo();
						this.$message.success("领取成功");
					} else {
						this.$message.error(data.msg);
					}
				} else {
					this.$router.push({
						path: "/",
					});
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.win {
		width: 450px;
		padding: 20px;
		background-color: #1c2230;
		position: relative;
		box-sizing: border-box;
		border-radius: 10px;

		.win-span2 {
			padding: 10px 0;
			height: 20px;
			background-color: #e9b10e;
			background-size: 100% 100%;
			border: 1px solid #ffea95;
			color: #000000;
			display: flex;
			font-weight: bold;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			margin-top: 15px;
		}

		.win-span2:hover {
			cursor: pointer;
		}
	}

	//中奖后盒子
	.win-box {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.7);
		display: grid;
		place-items: center;
	}

	.bind-box-task {
		color: #fff;
		padding: 10px;
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

		.box-item {
			background-color: #343a40;
			padding: 10px;
			display: flex;
			max-width: 500px;
			align-items: center;
			border-radius: 5px;

			.left {
				flex: 1;

				header {
					display: flex;
					align-items: center;
					font-size: 15px;

					img {
						width: 30px;
						margin-right: 7px;
					}
				}

				.use {
					margin: 10px 0;
					font-size: 16px;
				}

				.jiangli {
					display: flex;
					align-items: center;
					font-size: 12px;

					img {
						width: 15px;
						margin: 0 5px;
					}
				}
			}

			.right {
				width: 60px;
				font-size: 14px;
				color: #e9b10e;
				font-weight: bold;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}
</style>